import { tableResponse, tableUpdate } from "helpers/reducers";
import { MANAGE_TABLE } from "app/ManageTables/types";
import { FETCH_COMPARE_SUCCESS, TABLE_COMPARE_UPDATE } from "../types";
import { IGPTable } from "ui/Table/Table";

function TranslateCompareTable(
    state: IGPTable = {
        id: "TranslationCompareTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        // pagination: {
        //     page: 1,
        //     per_page: 20,
        //     isLastPage: true,
        //     total: 0,
        // },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { key: "asc" },
        with: [],
        withCount: [],
    },
    action: any
) {
    switch (action.type) {
        case FETCH_COMPARE_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                    selection: {
                        ...state.selection,
                        selected: [],
                    },
                };
            }
            return state;

        case TABLE_COMPARE_UPDATE:
            return tableUpdate(state, action);
        default:
            return state;
    }
}

export default TranslateCompareTable;
