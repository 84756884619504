import loadable from "@loadable/component";

interface IAsyncComponent {
    path: string;
    [k: string]: any;
}

const AsyncComponent = ({ path, ...props }: IAsyncComponent) => {
    const Component = loadable(() => import(`app/${[path]}`));
    return <Component {...props} />;
};

export default AsyncComponent;
