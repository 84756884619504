import SuppliersTableReducer from "./reducers/SuppliersTableReducer";
import SuppliersSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Inventory.suppliers";
export const url = "suppliers";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["inventory.suppliers.read"]) {
        config.active = true;

        store.reducerManager?.add(
            "InventorySupplierTable",
            SuppliersTableReducer
        );
        store.injectSaga?.("SuppliersSaga", SuppliersSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Inventory/Suppliers/pages/SuppliersIndexPage" />
            ),
        });
    }

    if (permissions["inventory.suppliers.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Inventory/Suppliers/pages/SuppliersCreatePage" />
            ),
        });
    }

    if (permissions["inventory.suppliers.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Inventory/Suppliers/pages/SuppliersEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
