import UsersTableReducer from "./reducers/UsersTableReducer";
import UsersSaga from "./saga";

import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import SimplePermissionsModal from "./modals/SimplePermissionsModal";

export const moduleName = "Account.users";
export const url = "users";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: permissions["account.user.read"],
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["account.user.read"]) {
        store.reducerManager?.add("AccountUserTable", UsersTableReducer);
        store.injectSaga?.("UsersSaga", UsersSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Account/Users/pages/UsersIndexPage" />
            ),
        });
    }

    if (permissions["account.user.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Account/Users/pages/UsersCreatePage" />
            ),
        });
    }

    if (permissions["account.user.update"]) {
        registerModals({ SimplePermissionsModal });

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Account/Users/pages/UsersEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
