import PredefinedListsTableReducer from "./reducers/PredefinedListsTableReducer";
import PredefinedListsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Booking.predefinedLists";
export const url = "predefined-lists";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["booking.predefined-lists.read"]) {
        config.active = true;

        store.reducerManager?.add(
            "PredefinedListTable",
            PredefinedListsTableReducer
        );
        store.injectSaga?.("PredefinedListsSaga", PredefinedListsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Booking/PredefinedLists/pages/PredefinedListsIndexPage" />
            ),
        });
    }

    if (permissions["booking.predefined-lists.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Booking/PredefinedLists/pages/PredefinedListsCreatePage" />
            ),
        });
    }

    if (permissions["booking.predefined-lists.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Booking/PredefinedLists/pages/PredefinedListsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
