import settingsConfig, {
    moduleName as settingsModuleName,
} from "./Settings/config";

import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import restaurantConfig, {
    moduleName as restaurantModuleName,
} from "./Restaurant/config";
import predefinedListsConfig, {
    moduleName as predefinedListsModuleName,
} from "./PredefinedLists/config";
import { registerModule } from "moduleConfig";
import ProjectContext from "app/App/context";

export const moduleName = "Booking";
export const url = `/booking`;

const getConfig = (permissions: permissionsType, settings: any) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];

    // register Settings module
    const settingsModule = settingsConfig(
        permissions,
        url,
        settings.projects["booking"]
    );
    if (settingsModule.config.active) {
        config.active = true;

        registerModule(settingsModuleName, settingsModule.config);
        routingChildrens.push(...settingsModule.routing);
    }

    //register Restaurant module
    const bc = restaurantConfig(permissions, url);
    if (bc.config.active) {
        config.active = true;
        registerModule(restaurantModuleName, bc.config);
        routingChildrens.push(...bc.routing);
    }

    //register Predefined Lists module
    const pl = predefinedListsConfig(permissions, url);
    if (pl.config.active) {
        registerModule(predefinedListsModuleName, pl.config);
        routingChildrens.push(...pl.routing);
    }

    if (routingChildrens.length) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: (
                <ProjectContext.Provider value={{ project: "booking", url }}>
                    <AsyncComponent path="Booking/index" />
                </ProjectContext.Provider>
            ),
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: moduleName,
                name: "Booking",
                icon: "table_restaurant",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
