import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import articleConfig from "./Articles/config";
import { registerModule } from "moduleConfig";

export const url = `/dn`;

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];

    const ac = articleConfig(permissions, url);

    if (ac.config.active) {
        config.active = true;

        registerModule("dn.articles", ac.config);
        routingChildrens.push(...ac.routing);
    }

    if (routingChildrens.length) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="DN/index" />,
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: "dn",
                name: "DN",
                icon: "newspaper",
                url: `${url}/articles`,
                priority: 900,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
