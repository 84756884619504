import RestaurantSelectModal from "./modals/RestaurantSelectModal";
import RestaurantSaga from "./saga";

import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";

export const moduleName = "Booking.restaurants";
export const url = "restaurants";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["booking.restaurants.read"]) {
        config.active = true;

        registerModals({
            RestaurantSelectModal,
        });
        store.injectSaga?.("RestauarntSaga", RestaurantSaga);

        // routing.push({
        //     path: url,
        //     element: (
        //         <AsyncComponent path="Booking/Restaurant/pages/BookingRestaurantIndexPage" />
        //     ),
        // });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
