import PotentialClientsStatusModal from "./modals/PotentialClientsStatusModal";
import PotentialClientsSellerModal from "./modals/PotentialClientsSellerModal";
import PotentialClientsTable from "./reducers/PotentialClientsTableReducer";
import PotentialClientsSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { registerProjects } from "rootProjects";
import { registerModals } from "modals";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";

export const moduleName = "PotentialClients";
export const url = "/potential-clients";

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["potential-clients.read"]) {
        config.active = true;

        store.reducerManager?.add(
            "PotentialCustomersTable",
            PotentialClientsTable
        );
        store.injectSaga?.("PotentialClientsSaga", PotentialClientsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="PotentialClients/pages/PotentialClientsIndexPage" />
            ),
        });
    }

    if (permissions["potential-clients.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="PotentialClients/pages/PotentialClientsCreatePage" />
            ),
        });
    }

    if (permissions["potential-clients.update"]) {
        registerModals({
            PotentialClientsStatusModal,
            PotentialClientsSellerModal,
        });
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="PotentialClients/pages/PotentialClientsEditPage" />
            ),
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: moduleName,
                name: "Potential Clients",
                icon: "point_of_sale",
                url,
                priority: 900,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
