import IConfig from "interfaces/IConfig";
import { permissionsType, routingType } from "types/configTypes";

export const moduleName = "online-booking.settings";
export const url = "settings";

const getConfig = (
    permissions: permissionsType,
    parentUrl: string,
    environments: any
) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (Object.keys(environments).length > 1) {
        config.active = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
