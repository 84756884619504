import GroupsTableReducer from "./reducers/GroupsTableReducer";
import GroupsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Portal.groups";
export const url = "groups";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["portal.groups.read"]) {
        config.active = true;

        store.reducerManager?.add("PortalGroupTable", GroupsTableReducer);
        store.injectSaga?.("GroupsSaga", GroupsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Portal/Groups/pages/GroupsIndexPage" />
            ),
        });
    }

    if (permissions["portal.groups.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Portal/Groups/pages/GroupsCreatePage" />
            ),
        });
    }

    if (permissions["portal.groups.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Portal/Groups/pages/GroupsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
