import ApplicationsTableReducer from "./reducers/ApplicationsTableReducer";
import ApplicationsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Account.application";
export const url = "applications";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: permissions["account.application.read"],
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["account.application.read"]) {
        store.reducerManager?.add(
            "AccountApplicationTable",
            ApplicationsTableReducer
        );
        store.injectSaga?.("ApplicationsSaga", ApplicationsSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Account/Applications/pages/ApplicationsIndexPage" />
            ),
        });
    }

    if (permissions["account.application.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Account/Applications/pages/ApplicationsCreatePage" />
            ),
        });
    }
    if (permissions["account.application.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Account/Applications/pages/ApplicationsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
