import api from "./api";
import {
    APP_INIT,
    APP_LOADED,
    SET_APP_INFO,
    SIDE_NAV_ITEM_CLICK,
    SIDE_NAV_TOGGLE,
} from "types";
import { modalShow, appSettings as appSettingsRequest } from "./actions";

import {
    call,
    debounce,
    fork,
    put,
    select,
    take,
    takeLatest,
} from "@redux-saga/core/effects";

import { me as meRequest } from "app/Me/actions";
import { ME_SUCCESS } from "app/Me/types";
import pathToAuth from "helpers/pathToAuth";
import { request } from "api/apiSaga";
import { AnyAction } from "redux";
import { loadModules } from "moduleSaga";
import theme from "ui/theme";
import { eventChannel } from "redux-saga";
import store from "rootStore";

interface IApplication {
    id: number;
    key: string;
    name: string;
}
interface IMe {
    response: {
        data: {
            data: {
                applications: IApplication[];
                email: string;
                first_name: string;
                last_name: string;
                permissions: Array<string>;
                phone: string;
                role: string;
            };
        };
    };
}

function* initApp() {
    yield put(meRequest());

    const me: IMe = yield take(ME_SUCCESS);

    const application = me.response.data.data.applications.find(
        (application: IApplication) =>
            application.key === process.env.REACT_APP_APPLICATION_UID
    );

    if (!application) {
        window.location.href = pathToAuth();
        return;
    }

    localStorage.removeItem("reloadedApp");

    // @ts-ignore
    const settings = yield call(appSettings, appSettingsRequest());

    //register routing, reducers etc
    yield call(loadModules, me.response.data.data.permissions, settings.data.data);

    yield fork(registerWindowEvents);

    yield put({
        type: APP_LOADED,
    });

    const showNewReleaseInfo = localStorage.getItem(
        `release-${process.env.REACT_APP_VERSION}`
    );

    if (showNewReleaseInfo === null && me.response.data.data.role === "admin") {
        yield put(modalShow("ReleaseModal", {}));
    }
}

function* registerWindowEvents() {
    const getAppInfo = () => {
        const height = window.innerHeight;
        const width = window.innerWidth;
        let navBarHeight = 0;

        if (width < theme.breakpoints.values.sm) {
            navBarHeight = 56;
        } else {
            navBarHeight = 64;
        }

        return {
            contentHeight: height - navBarHeight,
            isMobile: width < theme.breakpoints.values.lg,
            isTouchable:
                "ontouchstart" in window || navigator.maxTouchPoints > 0,
            height,
            navBarHeight,
            width,
        };
    };

    let drawerOpen = true;
    if (
        localStorage.getItem("drawerOpen") !== null &&
        localStorage.getItem("drawerOpen") === "false"
    ) {
        drawerOpen = false;
    }

    yield put({
        type: SET_APP_INFO,
        info: {
            ...getAppInfo(),
            drawerOpen,
            mobileOpen: false,
        },
    });

    const windowResized = eventChannel((emitter) => {
        window.addEventListener("resize", emitter);
        return () => window.removeEventListener("resize", emitter);
    });

    yield debounce(250, windowResized, () => {
        store.dispatch({ type: SET_APP_INFO, info: getAppInfo() });
    });
}

function* sideNavItemClick(action: AnyAction): any {
    const appInfo = yield select((state: any) => state.app.info);
    if (appInfo.isMobile || appInfo.isTouchable) {
        yield put({
            type: SET_APP_INFO,
            info: { ...appInfo, mobileOpen: false },
        });
    }
}

function* sideNavToggle(action: AnyAction): any {
    const appInfo = yield select((state: any) => state.app.info);

    if (appInfo.isMobile || appInfo.isTouchable) {
        yield put({
            type: SET_APP_INFO,
            info: { ...appInfo, mobileOpen: !appInfo.mobileOpen },
        });
    } else {
        localStorage.setItem("drawerOpen", (!appInfo.drawerOpen).toString());
        yield put({
            type: SET_APP_INFO,
            info: { ...appInfo, drawerOpen: !appInfo.drawerOpen },
        });
    }
}

export function* appSettings(action: AnyAction): any {
    return yield call(request, api.appSettings, action);
}

export const appSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(SIDE_NAV_ITEM_CLICK, sideNavItemClick),
    takeLatest(SIDE_NAV_TOGGLE, sideNavToggle),
];
