import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const restaurantsFetch = (
    applicationKey: string,
    config?: AxiosRequestConfig
) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "restaurantsFetch",
        {
            config: {
                ...config,
                params: { _app: applicationKey, ...config?.params },
            },
        }
    );
