import {
    Icon,
    IconButton,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableCellProps,
} from "@mui/material";

import { modalShow } from "app/App/actions";
import React, { createElement, ReactNode } from "react";
import usePermission from "hooks/usePermission";
import {
    IGPTableColumn,
    IGPTableSelection,
    IGPTableSort,
    IGPTableSortDirections,
} from "./Table";
import TableSelectorHead from "./TableSelectorHead";
import { useDispatch } from "react-redux";

interface IGPTableHead {
    availableColumns?: IGPTableColumn[];
    columns: IGPTableColumn[];
    deleteColumns?: Function;
    id: string;
    organizeColumns?: IGPTableColumn[];
    rows?: any;
    selection?: IGPTableSelection;
    selectionActions?: ReactNode;
    sort?: IGPTableSort;
    tableColumns?: any;
    tableUpdate?: Function;
}

function GPTableHead(props: IGPTableHead) {
    const dispatch = useDispatch();
    const updatePermission = usePermission("table-management.update");

    if (props.rows.length === 0) {
        return null;
    }

    const onClickSort = (item: IGPTableColumn) => (event: React.MouseEvent) => {
        let way = item.initSortDirection ? item.initSortDirection : "asc";

        if (props.sort && props.sort[item.field]) {
            way = props.sort[item.field] === "asc" ? "desc" : "asc";
        }

        props.tableUpdate?.("sort", {
            [item.field as string]: way,
        });
    };

    const renderHead = () => {
        const handleClickSettings = () => {
            dispatch(
                modalShow("TableModal", {
                    id: props.id,
                    columns: !!props.organizeColumns
                        ? props.organizeColumns
                        : props.columns,
                    availableColumns: props.availableColumns,
                })
            );
        };

        return props.columns.map((item: IGPTableColumn, idx: number) => {
            let content = {
                sx: { py: 1, whiteSpace: "nowrap" },
                width: item.width,
            } as TableCellProps;
            let headName = item.headName as string | React.ReactElement;

            if (item.renderHead !== undefined) {
                headName = item.renderHead(item);
            } else if (item.field === "actions") {
                if (props.availableColumns && updatePermission) {
                    headName = (
                        <IconButton
                            onClick={handleClickSettings}
                            sx={{ p: "5px" }}
                        >
                            <Icon>settings</Icon>
                        </IconButton>
                    );
                }

                content.align = "right";
                content.sx = {
                    ...content.sx,
                    backgroundColor: "#FFF",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    p: 0,
                    paddingLeft: "8px!important",
                    paddingRight: "8px!important",
                    position: "sticky",
                    right: 0,
                    whiteSpace: "nowrap",
                    width: 52,
                    zIndex: 3,
                };
            } else if (
                item.field === "row-selection" &&
                !!props.selection &&
                !!props.tableUpdate
            ) {
                headName = (
                    <TableSelectorHead
                        id={props.id}
                        rows={props.rows}
                        selection={props.selection}
                        selectionActions={props.selectionActions}
                        tableUpdate={props.tableUpdate}
                    />
                );
                content.sx = {
                    ...content.sx,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    position: "sticky",
                    left: 0,
                    paddingLeft: "0!important",
                    paddingRight: "0!important",
                    zIndex: 3,
                };
                content.width = 42;
            }

            if (item.sortable) {
                let active = false;
                let direction: IGPTableSortDirections = item.initSortDirection
                    ? item.initSortDirection
                    : "asc";

                if (props.sort && props.sort[item.field] !== undefined) {
                    active = true;
                    direction = props.sort[item.field];
                }

                content.children = (
                    <TableSortLabel
                        active={active}
                        direction={direction}
                        onClick={onClickSort(item)}
                    >
                        {headName}
                    </TableSortLabel>
                );
            } else {
                content.children = headName;
            }

            return createElement(TableCell, {
                key: `gui-head-cell-${idx}`,
                ...item.cellProps,
                ...content,
            });
        });
    };

    return (
        <TableHead>
            <TableRow>{renderHead()}</TableRow>
        </TableHead>
    );
}

GPTableHead.defaultProps = {};

export default GPTableHead;
