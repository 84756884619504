import settingsConfig, {
    moduleName as settingsModuleName,
} from "./Settings/config";
import translateConfig from "./Translate/config";

import ProjectContext from "app/App/context";
import IConfig from "interfaces/IConfig";
import { registerModule } from "moduleConfig";
import { registerRoutings } from "rootRouting";
import { registerProjects } from "rootProjects";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Agent";
export const url = `/agent`;

const getConfig = (permissions: permissionsType, settings: any) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    const routingChildrens = [];

    // register Settings module
    const settingsModule = settingsConfig(permissions, url, settings.projects["agent"]);
    if (settingsModule.config.active) {
        config.active = true;

        registerModule(settingsModuleName, settingsModule.config);
        routingChildrens.push(...settingsModule.routing);
    }

    //register Translate module
    const tr = translateConfig(permissions, url);

    if (tr.config.active) {
        registerModule("agent.translation", tr.config);
        routingChildrens.push(...tr.routing);
        config.active = true;
    }

    if (routingChildrens.length > 0) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: (
                <ProjectContext.Provider value={{ project: "agent", url }}>
                    <AsyncComponent path="Agent/index" />{" "}
                </ProjectContext.Provider>
            ),
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: "agent",
                name: "Agent",
                icon: "account_circle",
                url: `${url}`,
                priority: 400,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
