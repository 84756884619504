import CategoriesTableReducer from "./reducers/CategoriesTableReducer";
import CategoriesSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Inventory.categories";
export const url = "categories";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["inventory.categories.read"]) {
        config.active = true;

        store.reducerManager?.add(
            "InventoryCategoryTable",
            CategoriesTableReducer
        );
        store.injectSaga?.("CategoriesSaga", CategoriesSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Inventory/Categories/pages/CategoriesIndexPage" />
            ),
        });
    }

    if (permissions["inventory.categories.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Inventory/Categories/pages/CategoriesCreatePage" />
            ),
        });
    }
    if (permissions["inventory.categories.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Inventory/Categories/pages/CategoriesEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
