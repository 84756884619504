import LocationsTableReducer from "./reducers/LocationsTableReducer";
import LocationsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "event.locations";
export const url = "locations";
export const groupsUrl = `${url}/groups`;

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["event.locations.read"]) {
        config.active = true;

        store.reducerManager?.add("EventLocationTable", LocationsTableReducer);
        store.injectSaga?.("EventLocationsSaga", LocationsSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Event/Locations/pages/LocationsIndexPage" />
            ),
        });
    }

    if (permissions["event.locations.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Event/Locations/pages/LocationsCreatePage" />
            ),
        });
    }
    if (permissions["event.locations.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Event/Locations/pages/LocationsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
