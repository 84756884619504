import suppliersConfig, {
    moduleName as suppliersModuleName,
} from "./Suppliers/config";
import categoriesConfig, {
    moduleName as categoriesModuleName,
} from "./Categories/config";
import locationsConfig, {
    moduleName as locationsModuleName,
} from "./Locations/config";
import locationGroupsConfig, {
    moduleName as locationGroupsModuleName,
} from "./LocationGroups/config";
import organisationsConfig, {
    moduleName as organisationsModuleName,
} from "./Organisations/config";
import settingsConfig, {
    moduleName as settingsModuleName,
} from "./Settings/config";
import translateConfig, {
    moduleName as translateModuleName,
} from "./Translate/config";
import permissionsConfig, {
    moduleName as permissionsModuleName,
} from "./Permissions/config";

import { registerModule } from "moduleConfig";
import { registerProjects } from "rootProjects";
import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import { registerModals } from "modals";
import InventoryLocationsSelectModal from "./Permissions/modals/InventoryLocationsSelectModal";
import InventoryPermissionsModal from "./Permissions/modals/InventoryPermissionsModal";
import InventoryPermissionSaga from "./Permissions/saga";
import store from "rootStore";
import IConfig from "interfaces/IConfig";
import ProjectContext from "app/App/context";

export const moduleName = "Inventory";
export const url = `/inventory`;

const getConfig = (permissions: permissionsType, settings: any) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };
    let routing: routingType = [];

    if (permissions["app.access.inv"]) {
        store.injectSaga?.("InventoryPermissionSaga", InventoryPermissionSaga);
        registerModals({
            InventoryLocationsSelectModal,
            InventoryPermissionsModal,
        });
    }

    const routingChildrens = [];

    // register Settings module
    const settingsModule = settingsConfig(
        permissions,
        url,
        settings.projects?.["inventory"] ?? {}
    );
    if (settingsModule.config.active) {
        config.active = true;

        registerModule(settingsModuleName, settingsModule.config);
        routingChildrens.push(...settingsModule.routing);
    }

    // register Permissions module
    const permissionsModule = permissionsConfig(permissions, url);
    if (permissionsModule.config.active) {
        config.active = true;

        registerModule(permissionsModuleName, permissionsModule.config);
        routingChildrens.push(...permissionsModule.routing);
    }

    // register Locations module
    const locations = locationsConfig(permissions, url);
    if (locations.config.active) {
        config.active = true;
        registerModule(locationsModuleName, locations.config);
        routingChildrens.push(...locations.routing);
    }

    // register Suppliers module
    const suppliers = suppliersConfig(permissions, url);
    if (suppliers.config.active) {
        config.active = true;
        registerModule(suppliersModuleName, suppliers.config);
        routingChildrens.push(...suppliers.routing);
    }

    // register Categories module
    const categories = categoriesConfig(permissions, url);
    if (categories.config.active) {
        config.active = true;
        registerModule(categoriesModuleName, categories.config);
        routingChildrens.push(...categories.routing);
    }

    // register LocationGroups module
    const locationGroups = locationGroupsConfig(permissions, url);
    if (locationGroups.config.active) {
        config.active = true;
        registerModule(locationGroupsModuleName, locationGroups.config);
        routingChildrens.push(...locationGroups.routing);
    }

    // register Organisations module
    const organisations = organisationsConfig(permissions, url);
    if (organisations.config.active) {
        config.active = true;
        registerModule(organisationsModuleName, organisations.config);
        routingChildrens.push(...organisations.routing);
    }

    //register Translate module
    const translate = translateConfig(permissions, url);
    if (translate.config.active) {
        config.active = true;
        registerModule(translateModuleName, translate.config);
        routingChildrens.push(...translate.routing);
    }

    if (routingChildrens.length) {
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: (
                <ProjectContext.Provider value={{ project: "inventory", url }}>
                    <AsyncComponent path="Inventory/index" />{" "}
                </ProjectContext.Provider>
            ),
        });
    }

    if (config.active) {
        registerProjects([
            {
                id: moduleName,
                name: "Inventory",
                icon: "inventory",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
