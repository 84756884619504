import { registerModule } from "moduleConfig";
import serviceProviders from "serviceProviders";

export function loadModules(permissions, settings) {
    for (let serviceProviderName of Object.keys(serviceProviders)) {
        const serviceProvider = serviceProviders[serviceProviderName];
        const moduleConfig = serviceProvider(permissions, settings);

        if (!moduleConfig?.active) {
            continue;
        }

        registerModule(serviceProviderName, moduleConfig);
    }
}
