import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";
import { IColumns } from "./interfaces";

export const manageTable = (data: any) => ({
    type: types.MANAGE_TABLE,
    data,
});

export const manageTableGet = (id: string, app?: string, config?: AxiosRequestConfig) =>
    request(
        types.MANAGE_TABLE_REQUEST,
        types.MANAGE_TABLE_FAILED,
        types.MANAGE_TABLE_SUCCESS,
        "manageTableGet",
        {
            config: {
                params: { _app: app },
            },
            url: {
                id,
            },
        }
    );
export const manageTableDelete = (id: string, onResponse?: Function) =>
    request(
        types.MANAGE_TABLE_DELETE_REQUEST,
        types.MANAGE_TABLE_DELETE_FAILED,
        types.MANAGE_TABLE_DELETE_SUCCESS,
        "manageTableDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const manageTableUpdate = (
    id: string,
    data: IColumns,
    onResponse?: Function
) => {
    return request(
        types.MANAGE_TABLE_UPDATE_REQUEST,
        types.MANAGE_TABLE_UPDATE_FAILED,
        types.MANAGE_TABLE_UPDATE_SUCCESS,
        "manageTableUpdate",
        { data, url: { id } },
        onResponse
    );
};
