import AddTranslationModal from "app/Translate/modals/AddTranslationModal";
import TranslationsPushModal from "app/Translate/modals/TranslationsPushModal";
import TranslateReducer from "app/Translate/reducers/TranslateTableReducer";
import TranslateCompareReducer from "app/Translate/reducers/TranslateCompareTableReducer";
import TranslateSaga from "app/Translate/saga";

import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import { isProduction } from "helpers/isProduction";

export const permissionKey = "translation";
export const url = "translate";

export const getProject = (): string => {
    return window.location.pathname.split("/")[1].replaceAll("-", "_");
};

export const getPermissionKey = (): string => {
    return `${getProject()}.translation`;
};

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["translation.read"]) {
        registerModals({
            AddTranslationModal,
        });

        store.reducerManager?.add("TranslationTable", TranslateReducer);
        store.injectSaga?.("TranslateSaga", TranslateSaga);
    }

    if (permissions["translation.compare.update"] && isProduction()) {
        store.reducerManager?.add(
            "TranslationCompareTable",
            TranslateCompareReducer
        );
        registerModals({ TranslationsPushModal });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
