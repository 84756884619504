import IConfig from "interfaces/IConfig";
import ManageTables from "./saga";

import loadable from "@loadable/component";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType } from "types/configTypes";

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: true,
        routing: [],
        url: "",
    };

    store.injectSaga?.("ManageTables", ManageTables);

    if (permissions["table-management.update"]) {
        registerModals({
            TableModal: loadable(() => import("ui/Table/TableModal")),
        });
    }

    return config;
};

export default getConfig;
