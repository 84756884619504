import LocationGroupsTableReducer from "./reducers/LocationGroupsTableReducer";
import LocationGroupsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const moduleName = "Inventory.location_groups";
export const url = `locations/groups`;
const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["inventory.location_groups.read"]) {
        config.active = true;

        store.reducerManager?.add(
            "InventoryLocationGroupTable",
            LocationGroupsTableReducer
        );
        store.injectSaga?.("LocationGroupsSaga", LocationGroupsSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Inventory/LocationGroups/pages/LocationGroupsIndexPage" />
            ),
        });
    }

    if (permissions["inventory.location_groups.create"]) {
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="Inventory/LocationGroups/pages/LocationGroupsCreatePage" />
            ),
        });
    }
    if (permissions["inventory.location_groups.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Inventory/LocationGroups/pages/LocationGroupsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
