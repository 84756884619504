import InventoryPermissionsModal from "./modals/InventoryPermissionsModal"
import InventoryLocationsSelectModal from "./modals/InventoryLocationsSelectModal"
import PermissionsSaga from "./saga";
import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";

export const moduleName = "Inventory.permissions";
export const url = "organsations";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["permissions.read"]) {
        config.active = true;
        registerModals({
            InventoryLocationsSelectModal,
            InventoryPermissionsModal,
        });
        store.injectSaga?.("PermissionsSaga", PermissionsSaga);
        
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
