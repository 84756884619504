import { useMemo } from "react";
import store from "rootStore";

const usePermission = (permission) => {
    return useMemo(() => {
        return !!store.getState().me.permissions[permission];
    }, [permission]);
};

export default usePermission;
